<template>
    <seccion-datos :titulo="configuracion" class="card card-success" @cambios="guardar()" :botonGuardar="permisomodif">
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label>{{ $t('general.tipo') }}</label>
                    <select :disabled="!permisomodif" class="form-control" v-model="tipo_operario">
                        <option value="">----</option>
                        <option value="autonomo">{{ $t('general.autonomo') }}</option>
                        <option value="contratado">{{ $t('general.contratado') }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-8">
                <div class="form-group">
                    <label>{{ $t('general.idreparador') }}</label>
                    <input :disabled="!permisomodif" v-model="id_reparador" class="form-control nombre_completo dato"
                        id="nombre_completo" placeholder="">
                </div>
            </div>
        </div>
        <div class="row" v-if="mostrarpresup==1">
            <div class="col-md-12">
                <div class="form-group">
                    <label>{{ $t('general.generapresupuestos') }}</label>
                    <select v-model="genera_presupuestos" :disabled="!permisomodif" class="form-control">
                        <option value="0">{{ $t('general.nogenera') }}</option>
                        <option value="2">{{ $t('general.conbaremooperario') }}</option>
                        <option value="3">{{ $t('general.conbaremocompania') }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.descincr') }}</label>
                    <select :disabled="!permisomodif" v-model="incr" class="form-control">
                        <option value="0">{{ $t('general.desc') }}</option>
                        <option value="1">Incr</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>&nbsp;</label>
                    <input :disabled="!permisomodif" v-model="descuento_negociado_operario"
                        class="form-control nombre_completo dato" id="nombre_completo" placeholder="">
                </div>
            </div>
        </div>
        <div v-if="parametroAutofactura" class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>Método Auto-factura</label>
                    <select v-model="metodo_autofactura_operario" class="form-control">
                        <option title="Trabajos subsanados" value="">Trabajo</option>
                        <option title="Factyra (a cliente) realizada" value="FACTURA">Factura</option>
                        <option title="Factura o albaran (a cliente) realizado" value="FACTURA_ALBARAN">Factura albarán
                        </option>
                        <option title="Factura (a cliente) cobrada" value="COBRO">Cobro</option>
                        <option title="Todas las facturas (a cliente) del expediente cobradas" value="COBRO_TODO">Cobro
                            todo</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.subcont') }}</label>
                    <input type="text" v-model="subcuenta_operario" :disabled="!permisomodif" class="form-control">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="custom-control custom-checkbox">
                    <input :disabled="!permisomodif" v-model="modulo_operario" v-bind:true-value="1"
                        v-bind:false-value="0" type="checkbox" id="modulos" class="custom-control-input">
                    <label for="modulos" class="custom-control-label">{{ $t('general.modulos') }}</label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="custom-control custom-checkbox">
                    <input :disabled="!permisomodif" v-model="permitir_incrementos" v-bind:true-value="1"
                        v-bind:false-value="0" type="checkbox" class="custom-control-input" id="increment">
                    <label for="increment" class="custom-control-label">{{ $t('general.permitirincrementos') }}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="custom-control custom-checkbox">
                    <input :disabled="!permisomodif" v-model="envio_sms_automatico_operario" v-bind:true-value="1"
                        v-bind:false-value="0" type="checkbox" class="custom-control-input" id="enviosms"
                        placeholder="">
                    <label for="enviosms" class="custom-control-label">{{ $t('general.envioautomaticosms') }}</label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="custom-control custom-checkbox">
                    <input :disabled="!permisomodif" v-model="envio_email_automatico_operario" v-bind:true-value="1"
                        v-bind:false-value="0" type="checkbox" class="custom-control-input" id="envioemail"
                        placeholder="">
                    <label for="envioemail" class="custom-control-label">{{ $t('general.envioautomaticoemail')
                        }}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="custom-control custom-checkbox">
                    <input :disabled="!permisomodif" v-model="opcion_finaliza_visita" v-bind:true-value="1"
                        v-bind:false-value="0" type="checkbox" class="custom-control-input" id="visitaintranet">
                    <label for="visitaintranet" class="custom-control-label">{{ $t('general.finalizarvisitaintranet')
                        }}</label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="custom-control custom-checkbox">
                    <input :disabled="!permisomodif" v-model="supervisor" v-bind:true-value="1" v-bind:false-value="0"
                        type="checkbox" class="custom-control-input" id="supervisor">
                    <label for="supervisor" class="custom-control-label">{{ $t('general.supervisoroperarios') }}</label>
                </div>
            </div>
        </div>



        <div class="row">
            <div class="col-md-6">
                <div class="custom-control custom-checkbox">
                    <input class="custom-control-input" type="checkbox" id="preferente" :disabled="!permisomodif"
                        v-model="preferente_operario" v-bind:true-value="1" v-bind:false-value="0">
                    <label for="preferente" class="custom-control-label">{{ $t('general.preferente') }}</label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" id="datosperito" class="custom-control-input" :disabled="!permisomodif"
                        v-model="mostrar_datos_perito" v-bind:true-value="1" v-bind:false-value="0">
                    <label for="datosperito" class="custom-control-label">{{ $t('general.mostrardatosperito') }}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="custom-control custom-checkbox">
                    <input class="custom-control-input" type="checkbox" id="1visita" :disabled="!permisomodif"
                        v-bind:true-value="1" v-model="puede_citar" v-bind:false-value="0">
                    <label for="1visita" class="custom-control-label">{{ $t('general.puededarprimeravisita') }}</label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" v-model="siguientes_citas" id="2visita" :disabled="!permisomodif"
                        class="custom-control-input" v-bind:true-value="1" v-bind:false-value="0">
                    <label for="2visita" class="custom-control-label">{{ $t('general.puededarsegundavisita') }}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" id="autopresu" :disabled="!permisomodif" class="custom-control-input"
                            v-model="autoaprobado_operario" v-bind:true-value="1" v-bind:false-value="0">
                        <label for="autopresu" class="custom-control-label">{{ $t('general.autoaprovadospresupuestos')
                            }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5">
                <div class="custom-control custom-checkbox">
                    <input :disabled="!permisomodif" v-model="establecer_iva" v-bind:true-value="1"
                        v-bind:false-value="0" type="checkbox" class="custom-control-input" id="iva">
                    <label for="iva" class="custom-control-label">Establecer IVA<span v-if="establecer_iva == 1"> (%)</span></label>
                </div>
            </div>
            <div v-if="establecer_iva == 1" class="col-md-4">
                <input :disabled="!permisomodif" v-model="iva_operario" class="form-control dato" id="iva_operario"
                    placeholder="">
            </div>
            <div class="col-md-4">

            </div>
        </div>
        <!-- Se omite el acceso de email/clave ya que no se usa ni están contemplado en el acceso al webmail -->
    </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id', 'operario', 'permisomodif'],
    data() {
        return {
            parametroAutofactura:false,
            metodo_autofactura_operario:'',
            tipo_operario: '',
            id_reparador: '',
            incr: '',
            descuento_negociado_operario: '',
            modulo_operario: '',
            permitir_incrementos: '',
            opcion_finaliza_visita: '',
            supervisor: '',
            envio_email_automatico_operario: '',
            envio_sms_automatico_operario: '',
            configuracion: 'Configuración',
            establecer_iva: '',
            iva_operario: '',
            preferente_operario: '',
            mostrar_datos_perito: '',
            puede_citar: '',
            siguientes_citas: '',
            autoaprobado_operario: '',
            subcuenta_operario: '',
            genera_presupuestos: '',
            mostrarpresup:false,
        }
    },
    methods: {
        cargarDatosOperario() {
         this.$store.dispatch('cambiarCargando', true);
            console.log('opera', this.operario);
            this.tipo_operario = this.operario.tipo_operario;
            this.id_reparador = this.operario.id_reparador;  
            this.iva_operario = this.operario.iva_operario;          
            this.incr = this.operario.incr;
            this.puede_citar = this.operario.puede_citar;
            this.siguientes_citas = this.operario.siguientes_citas;
            this.autoaprobado_operario = this.operario.autoaprobado_operario;
            this.descuento_negociado_operario = this.operario.descuento_negociado_operario;
            this.modulo_operario = this.operario.modulo_operario;
            this.permitir_incrementos = this.operario.permitir_incrementos;
            this.opcion_finaliza_visita = this.operario.opcion_finaliza_visita;
            this.supervisor = this.operario.supervisor;
            this.subcuenta_operario = this.operario.subcuenta_operario;
            this.envio_email_automatico_operario = this.operario.envio_email_automatico_operario;
            this.envio_sms_automatico_operario = this.operario.envio_sms_automatico_operario;
            this.metodo_autofactura_operario = this.operario.metodo_autofactura;
            this.establecer_iva = this.operario.establecer_iva;
            this.preferente_operario = this.operario.preferente_operario;
            this.mostrar_datos_perito = this.operario.mostrar_datos_perito;
            this.genera_presupuestos = this.operario.genera_presupuestos;


            this.configuracion = this.$t("general.configuracion");
         this.$store.dispatch('cambiarCargando', false);
        },
        async guardar() {
            /* put  modulos/pwgsapi/index.php/operarios/:id */
            try {
                const api = new PwgsApi;
                const subidadatos = { genera_presupuestos: this.genera_presupuestos, subcuenta_operario: this.subcuenta_operario, autoaprobado_operario: this.autoaprobado_operario, siguientes_citas:this.siguientes_citas,puede_citar:this.puede_citar,mostrar_datos_perito: this.mostrar_datos_perito, preferente_operario: this.preferente_operario, iva_operario: this.iva_operario,establecer_iva: this.establecer_iva, metodo_autofactura:this.metodo_autofactura_operario, tipo_operario: this.tipo_operario, id_reparador: this.id_reparador, incr: this.incr, descuento_negociado_operario: this.descuento_negociado_operario, modulo_operario: this.modulo_operario, permitir_incrementos: this.permitir_incrementos, opcion_finaliza_visita: this.opcion_finaliza_visita, supervisor: this.supervisor, envio_email_automatico_operario: this.envio_email_automatico_operario, envio_sms_automatico_operario: this.envio_sms_automatico_operario };
                this.$store.dispatch('cambiarGuardando', true);
                await api.put('operarios/' + this.operario.id, subidadatos);
                this.$store.dispatch('cambiarGuardando', false);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async parametroAuto() {
            const api = new PwgsApi();
            var pa = await api.get('parametros-pwgs/metodo_autofactura_por_operario');
            if(pa.metodo_autofactura_por_operario=='1'){
                this.parametroAutofactura = true;
            }
        },
        async parametroPresup() {
            const api = new PwgsApi();
            let aux = await api.get('parametros-pwgs/operario_presupuestos');
            
            this.mostrarpresup = aux.operario_presupuestos;
            console.log("auxpresup", this.mostrarpresup);

        }
    },
    mounted() {
        this.cargarDatosOperario();
        this.parametroAuto();
        this.parametroPresup();
    },
    watch: {
        operario() {
            this.cargarDatosOperario();
        }
    },
}
</script>